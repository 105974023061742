import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import useStateCallback from "./useStateCallback";
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

// Our app
function App() {
  const Uploader = useRef(null);
  const [files, setFiles] = useState([]);
  const [filesCount, setfilesCount] = useState(0);
  const [filesUploaded, setfilesUploaded] = useState(0);
  const [urls, setUrl] = useState([]);
  const [isCopy, setCopy] = useState(false);
  const Clear = () => {
    Uploader.current.removeFiles();
    setfilesCount(0);
    setfilesUploaded(0);
    setFiles([]);
    setUrl([]);
  };

  const handleUploaded = (err, file) => {
    console.log(file.serverId);
    setUrl([...urls, file.serverId]);
    setfilesUploaded(filesUploaded + 1);
  };

  const urlStrings = () => {
    let result = "";
    for (let url of urls) {
      result += url + "\n";
    }
    return result;
  };

  useEffect(() => {
    if (filesCount == 0) {
      setfilesCount(files.length);
    }
    // for (let file of files) {
    //   console.log(file.filename);
    // }
  }, [files]);

  async function download() {
    //downloading with fetch()...
    const response = await fetch(
      "https://db.vrtech.me/uas/eGuyk56GWHVmrYpnkok1yMP7Qf52/17dde089401.glb"
    );
    const contentLength = response.headers.get("content-length");
    const total = parseInt(contentLength, 10);
    let loaded = 0;

    const res = new Response(
      new ReadableStream({
        async start(controller) {
          const reader = response.body.getReader();
          for (;;) {
            const { done, value } = await reader.read();
            if (done) break;
            loaded += value.byteLength;
            console.log(Math.round((loaded / total) * 100) + "%");
            // progress({ loaded, total });
            controller.enqueue(value);
          }
          controller.close();
        },
      })
    );
    const blob = await res.blob();
    // download completed
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    // the filename you want
    a.download = "17dde089401.glb";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    alert("your file has downloaded!"); // or you know, something with better UX...
  }

  let percent = (filesUploaded / filesCount) * 100;

  return (
    <Container>
      <button onClick={() => download()}>download</button>
      {filesCount > 0 && (
        <>
          <button
            style={{
              marginBottom: "10px",
              width: "100%",
              height: "40px",
              borderRadius: "8px",
              fontSize: "inherit",
              backgroundColor: "white",
            }}
            onClick={() => Clear()}
          >
            ลบทั้งหมด
          </button>
          {isCopy && (
            <div
              style={{
                textAlign: "center",
                color: "white",
              }}
            >
              คัดลอกสำเร็จ!
            </div>
          )}
          {files.length > 0 && filesCount === filesUploaded && (
            <CopyToClipboard
              text={urlStrings()}
              onCopy={() => {
                setCopy(true);
                setTimeout(() => {
                  setCopy(false);
                }, 1000);
              }}
            >
              <button
                style={{
                  marginBottom: "50px",
                  width: "100%",
                  height: "40px",
                  borderRadius: "8px",
                  fontSize: "inherit",
                  backgroundColor: "white",
                }}
              >
                คัดลอกลิ้ง
              </button>
            </CopyToClipboard>
          )}

          <div
            style={{
              textAlign: "center",
              color: "white",
              marginBottom: "40px",
            }}
          >
            {filesUploaded != filesCount ? (
              <span>
                กำลังอัปโหลด ... {percent.toFixed(0)}% ({filesUploaded} /{" "}
                {filesCount})
              </span>
            ) : (
              <span>อัปโหลดสำเร็จ! </span>
            )}
          </div>
        </>
      )}
      <FilePond
        ref={Uploader}
        files={files}
        onupdatefiles={setFiles}
        onprocessfile={(err, file) => handleUploaded(err, file)}
        allowMultiple={true}
        maxFiles={100}
        maxParallelUploads={12}
        chunkUploads={true}
        chunkSize={5000000}
        server={{
          // url: "https://backendadvanceuploader-production.up.railway.app/upload",
          // https://advanceuploader-backend.herokuapp.com/
          url: "http://localhost:5000/upload",
          process: (
            fieldName,
            file,
            metadata,
            load,
            error,
            progress,
            abort,
            transfer,
            options
          ) => {
            // fieldName is the name of the input field
            // file is the actual file object to send
            const formData = new FormData();
            formData.append(fieldName, file, file.name);
            const request = new XMLHttpRequest();
            request.open(
              "POST",
              // "https://backendadvanceuploader-production.up.railway.app/upload"
              // https://advanceuploader-backend.herokuapp.com/
              "http://localhost:5000/upload"
            );

            // Should call the progress method to update the progress to 100% before calling load
            // Setting computable to false switches the loading indicator to infinite mode
            request.upload.onprogress = (e) => {
              // progress(e.lengthComputable, e.loaded, e.total);
              progress(e.lengthComputable, e.loaded, e.total);
            };

            // Should call the load method when done and pass the returned server file id
            // this server file id is then used later on when reverting or restoring a file
            // so your server knows which file to return without exposing that info to the client
            request.onload = function () {
              if (request.status >= 200 && request.status < 300) {
                // the load method accepts either a string (id) or an object
                load(request.responseText);
              } else {
                // Can call the error method if something is wrong, should exit after
                error("oh no");
              }
            };

            request.send(formData);

            // Should expose an abort method so the request can be cancelled
            return {
              abort: () => {
                // This function is entered if the user has tapped the cancel button
                request.abort();

                // Let FilePond know the request has been cancelled
                abort();
              },
            };
          },
        }}
        name="files"
        labelIdle='ลากไฟล์ลงมาหรือกด <span class="filepond--label-action">ที่นี่</span>'
      />

      {/* {filesCount <= 0 && (
        <button
          style={{
            marginBottom: "50px",
            width: "100%",
            height: "40px",
            borderRadius: "8px",
            fontSize: "inherit",
            backgroundColor: "white",
          }}
          onClick={() => Clear()}
        >
          ดูอัลบั้ม
        </button>
      )} */}
    </Container>
  );
}

export default App;

const Container = styled.div`
  justify-content: center;
  align-items: center;
  flex-direction: column;

  min-height: 100vh;
  width: 100vw;

  @media (min-width: 30em) {
    .filepond--item {
      width: calc(50% - 0.5em);
    }
  }

  @media (min-width: 50em) {
    .filepond--item {
      width: calc(33.33% - 0.5em);
    }
  }
`;
